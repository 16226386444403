import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { storageHelpers } from "helpers/storageHelpers";

export default function LoggedInUser() {
  const isUserLoggedIn = storageHelpers.isUserLoggedIn();
  const location = useLocation();
  useEffect(() => {
    if (!isUserLoggedIn) toast.error("Trebuie sa te autentifici!");
  }, [isUserLoggedIn]);

  return isUserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      state={location.state}
      to={`/?redirect=${location.pathname}`}
      replace
    />
  );
}
