import Layout from "pages/Layout";
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { storageHelpers } from "helpers/storageHelpers";
import apiNotificariLegale from "api/notificariLegale.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { Link } from "react-router-dom";
const NotificariLegale = () => {
  const [notifications, setNotifications] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsDataFetching(true);
      const response = await apiNotificariLegale.getNotificariLegale();
      if (response.isSuccess) {
        setIsDataFetching(false);
        setNotifications(response.response);
      }

      const user = storageHelpers.getUserAccount();
      if (user) {
        console.log("🚀 ~ fetchNotifications ~ user", user);
      }
    };

    fetchNotifications();
  }, []);

  if (isDataFetching) {
    return <ComponentLoading />;
  }

  return (
    <Layout>
      <DataTable
        paginator
        rows={10}
        value={notifications}
        removableSort
        sortField="number"
        sortOrder={-1} // 1 asc, -1 desc
        loading={isDataFetching}
      >
        <Column
          sortable
          field="number"
          header="Numar"
          body={(rowData) => {
            return (
              <Link
                className="notificari-legale-number"
                to={`/notificare/${rowData.id}`}
                state={{ client_response: rowData.client_response }}
              >
                <div className="notificari-legale-number">
                  <p>{rowData.number}</p>
                </div>
              </Link>
            );
          }}
        />
        <Column
          sortable
          field="expiration"
          header="Expira la"
          body={(rowData) => {
            return (
              <div className="notificari-legale-date">
                <p>{rowData.expiration}</p>
              </div>
            );
          }}
        />
        <Column
          sortable
          field="release"
          header="Primita la"
          body={(rowData) => {
            return (
              <div className="notificari-legale-date">
                <p>{rowData.release}</p>
              </div>
            );
          }}
        />
      </DataTable>
    </Layout>
  );
};

export default NotificariLegale;
