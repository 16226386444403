import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { ReactComponent as ICON_CLOSE } from "assets/images/common/x_button.svg";
const ModalSemnareModificareConventie = ({
  setSignature,
  setVisible,
  signatureValue = null,
  description = "",
  labelSaveSignature = "",
  onSaveSignature = () => {},
}) => {
  let signPad = useRef({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  React.useEffect(() => {
    if (signatureValue && signPad?.current && signPad?.current.fromDataUrl) {
      signPad?.current.fromDataUrl(signatureValue);
    }
    function handleResize() {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const saveSignature = () => {
    if (
      signPad?.current &&
      signPad?.current?.isEmpty &&
      signPad?.current?.isEmpty()
    ) {
      toast.info("Trebuie să semnezi în chenar!");
      return;
    }
    let signatureData = signatureValue;
    if (signPad?.current && signPad?.current.toDataURL) {
      signatureData = signPad?.current?.toDataURL();
      setSignature(signatureData);
    }

    setVisible(false);
    onSaveSignature(signatureData);
  };

  const clearSignature = () => {
    setSignature(null);
    if (signPad?.current && signPad.current.clear) {
      signPad.current.clear();
    }
  };
  return (
    <>
      {ReactDOM.createPortal(
        <div className="modal-declaratie-container">
          <div className="modal-declaratie-content">
            <div className="modal-declaratie-header">
              <h2>Semnatura</h2>
              <ICON_CLOSE
                style={{ cursor: "pointer" }}
                onClick={() => setVisible(false)}
              />
            </div>
            <h2 className="modal-declaratie-title">{description}</h2>
            {signatureValue ? (
              <img
                className="signature-img"
                style={{
                  width: windowWidth > 768 ? 500 : 300,
                  height: 250,
                }}
                src={signatureValue}
                alt="signature"
              />
            ) : (
              <SignatureCanvas
                ref={signPad}
                canvasProps={{
                  width: windowWidth > 768 ? 500 : 300,
                  height: 250,
                  className: "signCanvas",
                }}
              />
            )}
            <div className="signature-buttons-container">
              <button
                onClick={() => clearSignature()}
                type="button"
                className="signature-button text-gray"
              >
                Șterge semnătura
              </button>
              <button
                onClick={() => saveSignature()}
                type="button"
                className="signature-button text-red"
              >
                {labelSaveSignature}
              </button>
            </div>
          </div>
        </div>,

        document.getElementById("modal-declaratie-modal"),
      )}
    </>
  );
};

export default ModalSemnareModificareConventie;
