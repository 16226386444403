import SelectInput from "components/SelectInput";

import GAZ from "assets/images/common/gaz.png";
import CURENT from "assets/images/common/curent.png";
import QUESTION from "assets/images/common/question.png";
import { ReactComponent as ICON_INCHIS } from "assets/images/support/inchis.svg";
import { ReactComponent as ICON_DESCHIS } from "assets/images/support/deschis.svg";
import { ReactComponent as ICON_IN_ASTEPTARE } from "assets/images/support/in_asteptare.svg";
import { ReactComponent as ICON_RESPINS } from "assets/images/support/respins.svg";
import objectSchema from "schemas/objectSchema";

const getIconTableStatus = (status) => {
  switch (status) {
    case "closed":
      return <ICON_INCHIS />;
    case "open":
      return <ICON_DESCHIS />;
    case "in_progress":
      return <ICON_IN_ASTEPTARE />;
    case "on_hold":
      return <ICON_IN_ASTEPTARE />;
    case "rejected":
      return <ICON_RESPINS />;
    default:
      return <ICON_INCHIS />;
  }
};

const getTextTableStatus = (status) => {
  switch (status) {
    case "merged":
    case "closed":
      return "Inchis";
    case "open":
      return "Deschis";
    case "in_progress":
      return "In curs";
    case "on_hold":
      return "In asteptare";
    case "rejected":
      return "Respins";
    default:
      return "Inchis";
  }
};
const tableTipTichet = (rowData) => {
  return (
    <div className="status-table-tipTichet">
      <p>{rowData.incident_type}</p>
    </div>
  );
};

const tableId = (rowData) => {
  return (
    <div className="status-table-id">
      <p>{rowData.id}</p>
    </div>
  );
};

const tableTipFurnizare = (rowData) => {
  return (
    <div className="status-table-tipFurnizare">
      <div
        className={`status-table-tipFurnizare-icon-container ${
          rowData.utility_type === "Electricitate"
            ? "curent-background"
            : "gaz-background"
        }`}
      >
        <img
          src={
            rowData.utility_type === "Electricitate"
              ? CURENT
              : rowData.utility_type === "Gaze"
                ? GAZ
                : QUESTION
          }
          alt="icon_gaz_curent"
        />
      </div>
      <p className="text-center">
        {rowData.utility_type === "Electricitate"
          ? "Energie electrica"
          : rowData.utility_type === "Gaze"
            ? "Gaze naturale"
            : "N/A"}
      </p>
    </div>
  );
};

const tableSubiect = (rowData) => {
  return (
    <div className="status-table-subiect" onClick={() => {}}>
      <a href={"notificare/55a44824-a27c-ef11-ac20-6045bd93f9fd"}>
        {rowData.subject}
      </a>
    </div>
  );
};

const tableLocDeConsum = (rowData) => {
  return (
    <div className="status-table-locDeConsum">
      <p>{rowData.metering_point_address}</p>
    </div>
  );
};

const tableDataProblemei = (rowData) => {
  return (
    <div className="status-table-dataProblemei">
      <p>{rowData.data}</p>
    </div>
  );
};
const tableResolution = (rowData) => {
  return (
    <div className="status-table-dataProblemei">
      <p>{rowData?.resolution?.details || "N/A"}</p>
    </div>
  );
};

const tableStatus = (rowData) => {
  return (
    <div className="status-table-status">
      {getIconTableStatus(rowData.status)}
      <p>{getTextTableStatus(rowData.status)}</p>
    </div>
  );
};

const header = (
  locuriConsum,
  setFilteredRowsTableData,
  rowsTableData,
  handleChangeFilter,
  handleChangeLocDeConsum,
  selectedTipFurnizare,
  selectedLocDeConsum,
  selectedStatus,
  selectedTipTichet,
) => {
  return (
    <div className="suport_table_header">
      <h2>Solicitari</h2>
      <div className="suport-table-row-selectors">
        <SelectInput
          reactSelectClassName="suport-table-tipTichet"
          isLabel={false}
          value={selectedTipTichet}
          onChange={(value) => {
            handleChangeFilter(
              value.value,
              "incident_type",
              rowsTableData,
              setFilteredRowsTableData,
            );
          }}
          placeholder="Tip tichet"
          options={objectSchema.optionsTipTichet}
        />
        <SelectInput
          reactSelectClassName="suport-table-locDeConsum"
          isLabel={false}
          value={selectedLocDeConsum}
          onChange={(value) => {
            handleChangeLocDeConsum(
              value.value,
              rowsTableData,
              setFilteredRowsTableData,
            );
          }}
          placeholder="Loc de consum"
          options={[
            { value: "TOATE", label: "Toate locurile de consum" },
            ...locuriConsum.map((item) => ({
              value: item.metering_point_id,
              label: (
                <div>
                  <img
                    src={item.utility_type === "Gaze" ? GAZ : CURENT}
                    height={"30px"}
                  />
                  {" " + item.address_summary}
                </div>
              ),
            })),
          ]}
        />
        <SelectInput
          reactSelectClassName="suport-table-tipFurnizare"
          isLabel={false}
          value={selectedTipFurnizare}
          onChange={(value) => {
            handleChangeFilter(
              value.value,
              "utility_type",
              rowsTableData,
              setFilteredRowsTableData,
            );
          }}
          placeholder="Tip furnizare"
          options={objectSchema.optionsTipFurnizare}
        />
        <SelectInput
          reactSelectClassName="suport-table-status"
          isLabel={false}
          value={selectedStatus}
          onChange={(value) => {
            handleChangeFilter(
              value.value,
              "status",
              rowsTableData,
              setFilteredRowsTableData,
            );
          }}
          placeholder="Status"
          options={objectSchema.optionsStatusSolicitareEng}
        />
      </div>
    </div>
  );
};

export const tableSuportHelper = {
  header,
  tableTipTichet,
  tableId,
  tableTipFurnizare,
  tableSubiect,
  tableLocDeConsum,
  tableDataProblemei,
  tableStatus,
  tableResolution,
};
