import Layout from "pages/Layout";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ModalSemnareModificareConventie from "components/LocDeConsum/ModalSemnareModificareConventie";
import { Button } from "primereact/button";
import apiNotificariLegale from "api/notificariLegale.service";
import ComponentLoading from "components/Loaders/ComponentLoading";
import { isString } from "lodash";
import { toast } from "react-toastify";
const base64ToBlobUrl = (base64, contentType = "application/pdf") => {
  try {
    // Convert base64 to a Uint8Array
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([bytes], { type: contentType });

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  } catch (e) {
    console.error("Error converting base64 string to blob URL:", e);
    // Return a fallback or indicate an error in a way that your app can handle.
    return "";
  }
};
const NotificareLegala = () => {
  const { id } = useParams();
  const [displaySignature, setDisplaySignature] = useState(false);
  const [signature, setSignature] = useState(null);
  const [selectedOption, setSelectedOption] = useState("NOTIFICARE");
  const [documents, setDocuments] = useState({
    act: { url: null },
    notificare: { url: null },
  });
  const location = useLocation();

  const [displaySignatureButton, setDisplaySignatureButton] = useState(false);

  useEffect(() => {
    const initSignatureForm = async () => {
      setDisplaySignatureButton(location?.state?.client_response !== 1);
    };
    initSignatureForm();
  }, [location?.state?.client_response]);

  console.log("🚀 ~ NotificareLegala ~  location.state :", location.state);

  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!isString(id) || id.length < 1) {
        console.warn("Invalid notification id");
        return;
      }
      setIsDataFetching(true);
      const response = await apiNotificariLegale.getNotificareLegala(id);
      console.log("🚀 ~ fetchNotifications ~ response:", response);

      if (response.isSuccess) {
        let receivedDocuments = {};
        if (response.response?.act) {
          receivedDocuments = {
            act: { url: base64ToBlobUrl(response.response?.act) },
          };
        }
        if (response.response?.notificare) {
          receivedDocuments = {
            ...receivedDocuments,
            notificare: { url: base64ToBlobUrl(response.response?.notificare) },
          };
        }
        setDocuments(receivedDocuments);
        try {
          const trackViewResponse =
            await apiNotificariLegale.trackViewNotificare(id);
          console.log(
            "🚀 ~ fetchNotifications ~ trackViewResponse:",
            trackViewResponse,
          );
        } catch (err) {
          console.error("Error tracking view notificare", err);
        }
      }

      setIsDataFetching(false);
    };

    fetchNotifications();
  }, [id]);

  if (isDataFetching) {
    return (
      <Layout>
        <ComponentLoading />
      </Layout>
    );
  }

  const requestSendSignature = async (data) => {
    if (!isString(data) || !isString(id) || id.length < 1 || data.length < 1) {
      console.warn("Invalid signature data or id");
      return;
    }
    const signatureData = data.replace("data:image/png;base64,", "");
    setIsDataFetching(true);
    const response = await apiNotificariLegale.sendSignature(id, signatureData);

    if (response.isSuccess) {
      setDisplaySignature(false);
      toast.success("Documentul a fost semnat cu succes");
      setDisplaySignatureButton(false);
    } else {
      toast.error("Eroare la trimiterea semnaturii");
    }
    setIsDataFetching(false);
  };
  return (
    <Layout>
      <div className="facturi_si_plati-selector-row">
        <div className="facturi_si_plati-selectors">
          <div
            onClick={() => setSelectedOption("NOTIFICARE")}
            className={
              selectedOption === "NOTIFICARE"
                ? "facturi_si_plati-option facturi_si_plati-option-selected"
                : "facturi_si_plati-option"
            }
          >
            <p>Notificare legala</p>
          </div>
          {documents?.act?.url && (
            <div
              onClick={() => setSelectedOption("ACT")}
              className={
                selectedOption === "ACT"
                  ? "facturi_si_plati-option facturi_si_plati-option-selected"
                  : "facturi_si_plati-option"
              }
            >
              <p>Contract aditional</p>
            </div>
          )}
        </div>
        <div className="autocitire-table-line" />
      </div>
      <div style={{ width: "100%" }}>
        {selectedOption === "ACT" ? (
          documents?.act?.url ? (
            <div>
              <iframe
                title="pdf file"
                width={"100%"}
                height={"500px"}
                className="pdf-preview"
                id="pdf-preview"
                src={documents?.act?.url}
              ></iframe>
            </div>
          ) : (
            <div style={{ padding: 10 }}>
              <p style={{ marginInlineStart: 10 }}>
                Documentul nu este disponibil
              </p>
            </div>
          )
        ) : null}
        {selectedOption === "NOTIFICARE" ? (
          documents?.notificare?.url ? (
            <div>
              <iframe
                title="pdf file"
                width={"100%"}
                height={"500px"}
                className="pdf-preview"
                id="pdf-preview"
                src={documents?.notificare?.url}
              ></iframe>
            </div>
          ) : (
            <div style={{ padding: 10 }}>
              <p style={{ marginInlineStart: 10 }}>
                Documentul nu este disponibil
              </p>
            </div>
          )
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 20,
          alignItems: "flex-end",
        }}
      >
        {selectedOption === "ACT" && displaySignatureButton && (
          <Button
            loading={isDataFetching}
            style={{
              width: 300,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
            onClick={() => {
              setDisplaySignature(true);
            }}
          >
            Semneazǎ act adițional
          </Button>
        )}

        {
          <div>
            <p>
              {selectedOption === "ACT" &&
                location?.state?.client_response === 1 &&
                "Actul adițional a fost semnat."}
            </p>
          </div>
        }
      </div>
      {/* )} */}
      {displaySignature && (
        <ModalSemnareModificareConventie
          setVisible={setDisplaySignature}
          signatureValue={signature}
          description="Pentru acceptarea notificǎrii, te rugǎm să semnezi în chenarul de mai jos"
          labelSaveSignature="Trimite semnatura"
          visible={displaySignature}
          setSignature={setSignature}
          onSaveSignature={requestSendSignature}
        />
      )}
    </Layout>
  );
};

export default NotificareLegala;
