import { requestJson, RequestMethod } from "helpers/httpHelper";

const getNotificariLegale = async () => {
  const response = await requestJson(
    "legal-notifications/list",
    RequestMethod.GET,
    {
      useAccessToken: true,
    },
  );

  return response;
};

const trackViewNotificare = async (id) => {
  const response = await requestJson(
    `legal-notifications/track-view?notification_id=${id}`,
    RequestMethod.GET,
    {
      useAccessToken: true,
    },
  );

  return response;
};

const getNotificareLegala = async (id) => {
  const response = await requestJson(
    "legal-notifications/detail?notification_id=" + id,
    RequestMethod.GET,
    {
      useAccessToken: true,
    },
  );

  return response;
};

const sendSignature = async (id, signatureData) => {
  const response = await requestJson(
    "legal-notifications/sign",
    RequestMethod.PATCH,
    {
      useAccessToken: true,
      data: {
        notification_id: id,
        signature: signatureData,
      },
    },
  );

  return response;
};

const apiNotificariLegale = {
  getNotificariLegale,
  getNotificareLegala,
  sendSignature,
  trackViewNotificare,
};
export default apiNotificariLegale;
